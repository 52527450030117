<template>
  <v-card
    ripple
    hover
    v-bind="$attrs"
    :tile="tile"
    :width="width"
    :height="card_height"
    :min-height="minHeight"
    :class="{ 'pa-0': !series || !options }"
  >
    <v-toolbar v-if="toolbar" dense color="secondary" tile dark>
      <v-toolbar-title>{{ label }}</v-toolbar-title>
      <v-spacer />
      <Global_refresh_button
        @click="$emit('update')"
        :loading="loading"
        :time="time"
      />
      <!--      <v-btn icon :loading="loading" @click="$emit('update')">-->
      <!--        <v-icon>fas fa-redo</v-icon>-->
      <!--      </v-btn>-->
    </v-toolbar>
    <loading_overlay :value="loading" v-if="loading" />
    <template v-else-if="series && options">
      <vue-apex-charts
        ref="chart"
        :type="type"
        :options="apexChartOptions"
        :series="series"
        :height="height"
       
      ></vue-apex-charts>
      <v-skeleton-loader
        type="card"
        :height="height"
        light
        transition="scale-transition"
        v-show="!appear"
        width="100%"
      ></v-skeleton-loader>
    </template>
    <template v-else>
      <no_data_to_show :label="error" />
    </template>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import loading_overlay from "@/components/loading_overlay";
export default {
  name: "v-apex-chart",
  components: {
    Global_refresh_button: () => import("@/components/global_refresh_button"),
    loading_overlay,
    no_data_to_show: () => import("@/components/no_data_to_show"),
    VueApexCharts
  },
  data() {
    return {
      appear: false,
      loaded: false,
      event: null
    };
  },
  computed: {
    apexChartOptions() {
      let options = this.options || {};
      if (options && Object.keys(options).length > 0) {
        if (typeof options.chart === typeof undefined) {
          options.chart.type = this.type;
        }
        options.chart.events = {
          beforeMount: () => {
            this.$emit("beforeMount");
            this.appear = true;
            this.loaded = false;
          },
          mounted: () => {
            this.$emit("mounted");
            this.loaded = true;
          },
          updated: () => {
            this.$emit("updated");
            this.loaded = true;
          }
        };
      }
      return options;
    }
  },
  props: {
    options: {
      type: Object,
      default: () => {}
    },
    series: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: "bar"
    },
    height: {
      type: [String, Number],
      default: 300
    },
    width: {
      type: [String, Number],
      default: "100%"
    },
    minHeight: {
      type: [String, Number],
      default: undefined
    },
    card_height: {
      type: [String, Number],
      default: undefined
    },
    label: {
      type: String,
      default: "No Records to show"
    },
    loading: {
      type: Boolean,
      default: false
    },
    toolbar: {
      type: Boolean,
      default: true
    },
    tile: {
      type: Boolean,
      default: false
    },
    time: {
      type: [String, Number],
      default: null
    },
    error: {
      type: [String],
      default: null
    }
  }
};
</script>

<style scoped></style>
