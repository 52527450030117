<template>
  <v-overlay
    :style="{ 'min-height': '300px', height: '100%;' }"
    absolute
    opacity="1"
    color="white"
    v-if="value"
  >
    <v-progress-circular
      class="mr-auto"
      indeterminate
      size="64"
      color="secondary"
    ></v-progress-circular>
    <h1 class="headline loading_text text--primary mt-1">
      <slot v-if="$slots.default" /><template v-else>Loading</template>
    </h1>
  </v-overlay>
</template>

<script>
export default {
  name: "loading_overlay",
  props: {
    value: {
      type: Boolean,
      default: () => false
    }
  }
};
</script>

<style scoped></style>
